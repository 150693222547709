const css = require("../css/style.styl")
const $ = require("jquery")
const request = require("superagent")
const slick = require("slick-carousel")
const slick_css = require("slick-carousel/slick/slick.css")
const $a = $("[data-animation-scroll='true']").each((i, element) => {
    const $_ = $(element)
    const $distination = $($_.attr("href").replace(/\//g, ""))
    if ($distination.length === 0) return false
    $_.on({
        click: () => {
            const t = $distination.offset().top
            $("html,body").animate({ scrollTop: t - 100 }, 300)
            return false
        }
    })
})

const $button = (() => {
    let open = false
    const $button = $("[data-event='click.menu']")
    const $menuWrapper = $("[data-object='click.menu']")
    $button.on({
        click: e => {
            if ($(window).width() > 1000) return false
            open ? $menuWrapper.removeClass("open") : $menuWrapper.addClass("open")
            open = !open
        }
    })
    return $button
})()

const $hideAtIE = (()=>{
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf("trident") !== -1 || ua.indexOf("msie") !== -1) $("[data-degrade-target='true']").remove()
})()

window.onload = ()=>{
    $(".to-reveal").addClass("revealed");
}

new Vue({
    el: "#year",
    data: { year: new Date().getFullYear() }
})